.container {
	height: 0;
	overflow: hidden;
	padding-bottom: 56.25%;
	padding-top: 30px;
	position: relative;
}

.container iframe {
	height: 100%;
	left: 0;
	position: absolute;
	top: 0;
	width: 100%;
}